<!--
 * @Author: huadan
 * @Date: 2021-04-16 11:01:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 11:39:33
 * @Description: 员工管理
-->

<style lang="less" scoped>
.employee {
  display: flex;
  flex-direction: column;

  .title {
    font-size: @font_size_6;
    font-weight: bold;
    margin: 30px 0 20px 20px;
  }

  .employee-table {
    margin: 0 20px;

    .filter-button {
      width: 88px;
    }

    .quit {
      color: @text_color_3;
    }

    .table-operations {
      .form-item {
        margin-bottom: 10px;
      }

      .range-picker {
        width: 100% !important;
      }
    }
  }
  /deep/.form-item.ant-row.ant-form-item {
    display: flex;
  }
  /deep/.ant-form-item-label {
    width: 100px;
    padding-left: 20px;
    text-align: center;
  }
  /deep/.ant-form-item-control-wrapper {
    flex: 1;
  }
}
</style>

<template>
  <NeoPageLayout class="employee">
    <a-row class="title">
      <a-col :span="8">{{ $t('employee.title') }}</a-col>
    </a-row>
    <!-- 员工管理 -->
    <div class="employee-table">
      <!-- 员工管理表格过滤 -->
      <div class="table-operations mb-2">
        <a-form-model ref="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row>
            <a-col :span="11">
              <a-form-model-item
                :label="$t('employee.filter_employee_code')"
                class="form-item"
                :label-col="{ span: 1 }"
                :wrapper-col="{ span: 20 }"
                prop="employeeCode"
              >
                <a-input v-model="form.employeeCode" :placeholder="$t('employee.input_employee_code')" />
              </a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item
                class="form-item"
                :label="$t('employee.filter_role')"
                :label-col="{ span: 1 }"
                :wrapper-col="{ span: 20 }"
                prop="seniorManager"
              >
                <a-select v-model="form.seniorManager" :placeholder="$t('employee.select_role')">
                  <a-select-option value="0"> {{ $t('employee.pm') }} </a-select-option>
                  <a-select-option value="1"> {{ $t('employee.spm') }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="1">
              <a-form-model-item class="form-item" :wrapper-col="{ span: 1, offset: 2 }">
                <a-button @click="resetSearch()" class="filter-button ml-2">{{ $t('reset') }}</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-model-item
                :label="$t('employee.filter_employee_name')"
                class="form-item"
                :label-col="{ span: 1 }"
                :wrapper-col="{ span: 20 }"
                prop="realName"
              >
                <a-input v-model="form.realName" :placeholder="$t('employee.input_employee_name')" />
              </a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item
                class="form-item"
                :label="$t('employee.filter_status')"
                :label-col="{ span: 1 }"
                :wrapper-col="{ span: 20 }"
                prop="status"
              >
                <a-select v-model="form.status" :placeholder="$t('employee.select_status')">
                  <a-select-option value="1"> {{ $t('employee.on') }} </a-select-option>
                  <a-select-option value="0"> {{ $t('employee.quit') }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="1">
              <a-form-model-item class="form-item" :wrapper-col="{ span: 1, offset: 2 }">
                <a-button type="primary" @click="searchTable()" class="filter-button ml-2">{{ $t('search') }}</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-model-item
                class="form-item"
                :label="$t('employee.filter_time')"
                :label-col="{ span: 1 }"
                :wrapperCol="{ span: 20 }"
              >
                <a-range-picker
                  v-model="updateDate"
                  class="range-picker"
                  :show-time="{
                    hideDisabledOptions: true,
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                  }"
                  format="YYYY-MM-DD HH:mm:ss"
                  @change="onUpdateDateChange"
                >
                  <a-icon slot="suffixIcon" type="calendar" />
                </a-range-picker>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <!-- 员工管理表格 -->
      <a-table
        class="head-bg no-br"
        rowKey="employeeCode"
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: $g.scrollXWidth }"
        size="middle"
        bordered
      >
        <!--  角色列 -->
        <template slot="role" slot-scope="text, record">
          {{ record.seniorManager == 0 ? $t('employee.pm') : record.seniorManager == 1 ? $t('employee.spm') : '' }}
        </template>
        <!--  状态列 -->
        <template slot="status" slot-scope="text, record">
          <span v-if="record.status == 1">
            {{ $t('employee.on') }}
          </span>
          <span v-else class="quit">
            {{ $t('employee.quit') }}
          </span>
        </template>
        <!--  操作列 -->
        <template slot="action" slot-scope="text, record">
          <span v-if="record.status == 1">
            <a v-if="record.id" class="mr-3" @click="setStatus(record.id, 0)">{{ $t('employee.set_quit') }}</a>
            <a @click="editRole(record)">{{ $t('edit') }}</a>
          </span>
          <span v-else>
            <a v-if="record.id" @click="setStatus(record.id, 1)">{{ $t('employee.set_on') }}</a>
          </span>
        </template>
      </a-table>
      <!--  分页 -->
      <a-pagination
        class="mt-3 fr"
        show-size-changer
        v-model="current"
        :total="total"
        :page-size="pageSize"
        @change="onChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <!-- 设置员工角色 -->
    <a-modal
      :title="$t('employee.edit_role')"
      :cancelText="$t('cancel')"
      :okText="$t('confirm')"
      :visible="modalVisible"
      @cancel="modalVisible = false"
      @ok="handleOk"
    >
      <a-form-model ref="form" :model="edit_role_form" :label-col="{ span: 2 }" :wrapper-col="{ span: 18 }">
        <a-form-model-item :label="$t('employee.filter_role')" prop="seniorManager">
          <a-select v-model="edit_role_form.seniorManager" :placeholder="$t('employee.select_role')">
            <a-select-option value="0"> {{ $t('employee.pm') }} </a-select-option>
            <a-select-option value="1"> {{ $t('employee.spm') }} </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </NeoPageLayout>
</template>

<script>
import moment from 'moment'

export default {
  name: 'employee',
  data() {
    return {
      pagination: {},
      loading: false,
      //员工管理表格列
      columns: [
        {
          title: this.$t('employee.table_name'),
          dataIndex: 'realName',
          key: 'realName',
          width: '400px',
        },
        {
          title: this.$t('employee.table_num'),
          dataIndex: 'originId',
          key: 'originId',
          width: '200px',
        },
        {
          title: this.$t('email'),
          dataIndex: 'email',
          key: 'email',
          width: '300px',
        },
        {
          title: this.$t('employee.filter_role'),
          dataIndex: 'seniorManager',
          key: 'seniorManager',
          scopedSlots: { customRender: 'role' },
        },
        {
          title: this.$t('employee.filter_status'),
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: this.$t('employee.filter_time'),
          dataIndex: 'updateDate',
          key: 'updateDate',
          width: '200px',
        },
        {
          title: this.$t('orderList.order_action'),
          dataIndex: 'status',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: '200px',
        },
      ],
      //员工管理表格数据
      dataSource: [],
      //员工管理表格每页显示大小
      pageSize: 10,
      //员工管理表格当前页
      current: 1,
      //员工管理表格总数
      total: 50,
      labelCol: { span: 2 },
      wrapperCol: { span: 20 },
      //查询参数
      form: {
        status: undefined,
        employeeCode: '',
        realName: '',
        seniorManager: undefined,
        startUpdateDate: undefined,
        endUpdateDate: undefined,
      },
      searchForm: {},
      //查询更新时间
      updateDate: undefined,
      //编辑角色
      edit_role_form: {
        id: '',
        seniorManager: undefined,
        email: undefined,
        employeeCode: undefined,
        realName: undefined,
        role: undefined,
        status: undefined,
      },
      modalVisible: false,
    }
  },

  mounted() {
    this.fetch()
  },

  methods: {
    //页码改变的回调，参数是改变后的页码及每页条数
    onChange(page, pageSize) {
      this.current = page
      this.pageSize = pageSize
      this.fetch(this.searchForm)
    },

    //pageSize 变化的回调
    onShowSizeChange(current, size) {
      this.current = current
      this.pageSize = size
      this.fetch(this.searchForm)
    },

    //表格更新数据
    async fetch(params = {}) {
      try {
        this.loading = true
        const data = await this.$http('companyUser', {
          limit: this.pageSize,
          page: this.current,
          ...params,
        })
        this.loading = false
        // 如果搜索的内容存在，但是当前非首页，则current改为1重新请求
        // const isRefetch = type === 'search' && data.list.length === 0 && data.total > 0 && this.current !== 1
        // if (isRefetch) {
        //   this.current = 1
        //   this.fetch(this.form)
        // }
        this.dataSource = data.list
        this.total = data.total
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    //查询
    searchTable() {
      this.current = 1
      this.searchForm = { ...this.form }
      this.fetch(this.searchForm)
    },

    // 重置表单
    resetSearch() {
      // 清空form对象
      for (const key in this.form) {
        this.form[key] = undefined
      }
      this.updateDate = undefined
      this.$refs.form.resetFields()
      this.searchForm = { ...this.form }
      this.fetch()
    },

    onUpdateDateChange(date, dateString) {
      this.form.startUpdateDate = dateString[0]
      this.form.endUpdateDate = dateString[1]
    },
    // 清空form对象
    clearForm() {
      for (const key in this.form) {
        this.form[key] = undefined
      }
      this.updateDate = undefined
      this.searchForm = { ...this.form }
    },
    //设置在离职
    async setStatus(id, status) {
      try {
        this.loading = true
        await this.$http('companyUserStatus', {
          id: id,
          status: status,
        })
        this.loading = false
        this.$message.success(this.$t('managerInfo.set_success_content'))
        this.clearForm()
        this.fetch()
        this.$refs.form.resetFields()
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    //编辑角色
    editRole(val) {
      this.edit_role_form.id = val.id
      if (val.seniorManager == null) {
        this.edit_role_form.seniorManager = undefined
      } else {
        this.edit_role_form.seniorManager = '' + val.seniorManager
      }
      this.edit_role_form.email = val.email
      this.edit_role_form.status = val.status
      this.edit_role_form.realName = val.realName
      this.edit_role_form.employeeCode = val.employeeCode
      this.modalVisible = true
    },

    //保存修改的角色
    async handleOk() {
      try {
        this.loading = true
        await this.$http('companyUserRole', {
          id: this.edit_role_form.id,
          role: this.edit_role_form.seniorManager,
          employeeCode: this.edit_role_form.employeeCode,
          realName: this.edit_role_form.realName,
          status: this.edit_role_form.status,
          email: this.edit_role_form.email,
        })
        this.clearForm()
        this.loading = false
        this.$message.success(this.$t('prompt.edit_success'))
        this.modalVisible = false
        this.fetch()
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 日期时间选择框的选取控制
    moment,
    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },

    // disabledRangeTime(_, type) {
    //   if (type === 'start') {
    //     return {
    //       disabledHours: () => this.range(0, 60).splice(4, 20),
    //       disabledMinutes: () => this.range(30, 60),
    //       disabledSeconds: () => [55, 56],
    //     }
    //   }
    //   return {
    //     disabledHours: () => this.range(0, 60).splice(20, 4),
    //     disabledMinutes: () => this.range(0, 31),
    //     disabledSeconds: () => [55, 56],
    //   }
    // },
    // 日期时间选择框的选取控制
  },
}
</script>
